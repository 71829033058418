import React, { useEffect, useState } from "react";
import NavbarLayout from "../components/NavbarLayout";
import Header from "../components/Dashboard/Header";
import ActivityTime from "../components/Dashboard/ActivityTime";
import LineCharts from "../components/LineCharts/LineCharts";
import ProductivityChart from "../components/Dashboard/ProductivityChart";
import WorkedHours from "../components/Dashboard/WorkedHours";
import Idlehours from "../components/Dashboard/Idlehours";
import ProductiveDetails from "../components/Dashboard/ProductiveDetails";
import Screenshot from "../components/Dashboard/Screenshot";
import "../styles/dashboard.scss";
import "../styles/linechart.scss";
import { commonGetService } from "../utils/properties";
import { useAppSelector } from "../app/hooks";
import moment from "moment";
import { getCookie, handleFilterApi } from "../utils/properties";
import { useNavigate } from "react-router";
import Timelines from "../components/Dashboard/Timelines";
import TimeLineTable from "../components/TimelineCharts/TimeLineTable";
import { showToast } from "../features/Toaster/toastslice";
import { useAppDispatch } from "../app/hooks";

export interface ActivityTimes {
  productive_time: string;
  productive_percentage: string;
  unproductive_time: string;
  unproductive_percentage: string;
  neutral_time: string;
  neutral_percentage: string;
  total_time: string;
  total_active_user: number;
  total_active_user_percentage: string;
  idle_time?: string;
  idle_time_percentage: number;
  last_updated?: string;
}

export interface HoursTracked {
  label: string;
  total_time: number;
  dateRange: string;
}
export interface AccessDetails {
  id: string,
  name: string,
  status: boolean,
  page_name: string
}

export interface AccessInfo {
  [index: string]: boolean
}

export interface activityProps {
  activityDetails: ActivityTimes | undefined,
  isLoading: boolean,
  accessInfo?: AccessInfo,
  isToday?: boolean,
  isYesterday?: boolean,
  isTablet?: boolean
}


// export const activityContext = React.createContext<ActivityTimes | null>(null);
export const activityContext = React.createContext<activityProps | null>(null);

const Dashboard = () => {
  const [activityDetails, setActivityDetails] = useState<ActivityTimes>();
  const [isToday, setIsToday] = useState<boolean>(true);
  const [isYesterday, setIsYesterday] = useState<boolean>(false);
  const [hoursTracked, setHoursTracked] = useState<HoursTracked[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [accessInfo, setAccessInfo] = useState<AccessInfo>({});
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth <= 991);

  const filterData = useAppSelector((state) => state.filterData);
  const { startDate, endDate, empDeviceId, filterType, organization, department } = filterData; // Destructuring the filterData state value
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (accessInfo["Activity Time"]) {
      getActivityDetails();
    }
    else {
      if (sessionStorage.getItem("isLogin")) {
        setTimeout(() => {
          setIsLogin(false);
          sessionStorage.removeItem("isLogin");
        }, 2600)
      }
    }

    if (accessInfo["Hours Tracked"] && !checkDate()) {
      getHoursTracked();
    }
  }, [filterData, isToday, isYesterday, accessInfo]);

  //Get the details for Roles based UI
  useEffect(() => {
    if (localStorage.getItem("roleDetails")) {
      let roleDetails = JSON.parse(localStorage.getItem("roleDetails") as string);
      let accessInfo: AccessInfo = {}
      roleDetails?.features?.dashboard?.features?.map((res: AccessDetails) => {
        accessInfo[res.name] = res?.status;
      })
      setAccessInfo(accessInfo);
    }
  }, [])

  //Identify the Tablet view or Desktop view
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkDate = () => {
    let today = moment(new Date()).format("L");
    let yesterday = moment(moment().subtract(1, "day").toDate()).format("L");
    let fromDate = moment(startDate).format("L");
    let toDate = moment(endDate).format("L");
    setIsToday(today === fromDate && today === toDate);
    setIsYesterday(yesterday === fromDate && yesterday === toDate);
    return (
      (today === fromDate && today === toDate) ||
      (yesterday === fromDate && yesterday === toDate)
    );
  };

  const getActivityDetails = () => {
    setIsLoading(true);
    commonGetService(`/api/1.0/dashboard/trends?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, false)}`).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setActivityDetails(res?.data);

        if (sessionStorage.getItem("isLogin")) {
          setTimeout(() => {
            setIsLogin(false);
            sessionStorage.removeItem("isLogin");
          }, 2600)
        }
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getHoursTracked = () => {
    setIsChartLoading(true);
    commonGetService(
      `/api/1.0/dashboard/tracked/hours?${handleFilterApi(startDate, endDate, filterType, organization, empDeviceId, department, true)}`
    ).then((res) => {
      setIsChartLoading(false);
      if (res.status === 200) {
        setHoursTracked(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const handleCommonCardRender = (type: string) => {
    let commonValidCheck = (!accessInfo["Haven't Tracked"] && !accessInfo["Productivity Insights"] && !accessInfo["Top Used Productive Apps"] &&
      !accessInfo["Top Used Unproductive Apps"] && !accessInfo["Top Used Neutral Apps"]) ||
      (accessInfo["Productivity Insights"] && (isToday || isYesterday) && !accessInfo["Haven't Tracked"] &&
        !accessInfo["Top Used Productive Apps"] && !accessInfo["Top Used Unproductive Apps"] && !accessInfo["Top Used Neutral Apps"]);

    if (type === 'productiveDetails') {
      if (commonValidCheck) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (type === 'productiveChart') {
      if (commonValidCheck && accessInfo["Activity Time"]) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (type === 'workedHours') {
      if (commonValidCheck && (!accessInfo["Highest Productive Time"] && !accessInfo["Highest Unproductive Time"]) &&
        accessInfo["Activity Time"]) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (type === 'idleHours') {
      if (commonValidCheck && (!accessInfo["Highest Productive Time"] && !accessInfo["Highest Unproductive Time"]) &&
        (!accessInfo["Worked Most Hours"] && !accessInfo["Worked Least Hours"]) && accessInfo["Activity Time"]) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (type === 'screenshot') {
      if (commonValidCheck && (!accessInfo["Highest Productive Time"] && !accessInfo["Highest Unproductive Time"]) &&
        (!accessInfo["Worked Most Hours"] && !accessInfo["Worked Least Hours"]) &&
        (!accessInfo["Currently Working Employees"] && !accessInfo["Highest Idle Minutes"]) &&
        accessInfo["Activity Time"]) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  return (
    <>
      {sessionStorage.getItem("isLogin") && isLogin ?
        <div className="loader-cls w-100 vh-100">
          <div className="d-flex justify-content-center align-items-center h-100">
            <img src="/images/loader/login_loader.gif" />
          </div>
        </div>
        :
        <activityContext.Provider value={{ activityDetails, isLoading, isToday, isYesterday, isTablet, accessInfo } || null}>
          <NavbarLayout>
            <Header />
            <div className="data-container">
              {accessInfo["Activity Time"] &&
                <ActivityTime />
              }
              {filterType === "employee" && accessInfo["Timeline"] && (
                <div className={`line-chart-ctr timeline-chart-ctr 
                ${!accessInfo["Activity Time"] ? 'timeline-to-chart-adjust' : ''}`}>
                  {/* <Timelines /> */}
                  <TimeLineTable />
                </div>
              )}
              {!(isToday || isYesterday) && accessInfo["Hours Tracked"] && (
                <div
                  className={`line-chart-ctr ${isToday || isYesterday ? "d-none" : "d-block"
                    } ${hoursTracked.length > 15 && "line-overflow"} ${(filterType === "employee" &&
                      accessInfo["Timeline"]) || (!accessInfo["Activity Time"]) ? "timeline-to-chart-adjust" : ""}
                     styled-scroll-hor`}
                >
                  <LineCharts
                    trackedHours={hoursTracked}
                    chartLoading={isChartLoading}
                  />
                </div>
              )}
              <div className={
                `${accessInfo["Activity Time"] && (
                  ((isToday || isYesterday) && filterType !== "employee") ||
                  (!(isToday || isYesterday) && filterType !== "employee" && !accessInfo["Hours Tracked"]) ||
                  (filterType === "employee" && !accessInfo["Timeline"] && !accessInfo["Hours Tracked"]) ||
                  ((isToday || isYesterday) && filterType === "employee" && !accessInfo["Timeline"])
                ) ? "visibility-check" : ""} 
                ${handleCommonCardRender("productiveDetails") ? 'd-none' : 'd-block'}`
              }
              >
                <ProductiveDetails displayBarChart={isToday || isYesterday} />
              </div>
              {filterType !== "employee" && (
                <>
                  <ProductivityChart cusMarginClass={handleCommonCardRender('productiveChart') ? 'cus-chart-mrn' : ''} />
                  <WorkedHours cusMarginClass={handleCommonCardRender('workedHours') ? 'cus-hour-mrn' : ''} />
                  <Idlehours cusMarginClass={handleCommonCardRender('idleHours') ? 'cus-idle-mrn' : ''} />
                </>
              )}
              {accessInfo["Recent Screenshots"] &&
                <Screenshot cusMarginClass={handleCommonCardRender('screenshot') ? 'cus-scrn-mrn' : ''} />
              }
            </div>
          </NavbarLayout>
        </activityContext.Provider >
      }
    </>
  );
};

export default Dashboard;
