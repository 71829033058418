import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Col, Image, ProgressBar } from "react-bootstrap";
import Barcharts from "../BarCharts/Barcharts";
import "../../styles/dashboard.scss";
import AppsSkeleton from "../Skeletons/AppsSkeleton";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../features/Toaster/toastslice";
import NotTrackedList from "./NotTrackedList";
import { activityContext, AccessInfo } from "../../pages/Dashboard";

export interface InsigtsData {
  department: string;
  total_hours: number;
  productive_hours: number;
  unproductive_hours: number;
  neutral_hours: number;
  idle_hours: number;
}

export interface InsigtsData1 {
  day: string;
  timestamp: string;
  total_hours: number;
  productive_hours: number;
  unproductive_hours: number;
  neutral_hours: number;
  idle_hours: number;
}
export interface UnTracked {
  image: string;
  email: string;
  department: string;
  designation: string;
  employee_id: number;
  employee_code: string;
  employee_name: string;
  reason: string;
  device_id: string;
  date: number;
}
interface WebApps {
  app: string;
  time: string;
  percentage: number;
}

export interface ProductiveDetailsProps {
  displayBarChart: boolean;
}

const ProductiveDetails: React.FC<ProductiveDetailsProps> = ({
  displayBarChart,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productiveLoading, setProductiveLoading] = useState<boolean>(false);
  const [unProductiveLoading, setUnProductiveLoading] = useState<boolean>(false);
  const [neutralLoading, setNeutralLoading] = useState<boolean>(false);
  const [trackedLoading, setTrackedLoading] = useState<boolean>(false);
  const [unTrackedUsers, setUntrackedUsers] = useState<UnTracked[]>([]);
  const [initialload, setInitialLoad] = useState<boolean>(true);
  const [productiveApps, setProductiveApps] = useState<WebApps[]>([]);
  const [unProductiveApps, setUnproductiveApps] = useState<WebApps[]>([]);
  const [neutralApps, setNeutralApps] = useState<WebApps[]>([]);
  const [insightsData, setInsightsData] = useState<InsigtsData[]>([]);
  const [insightsLoading, setInsightsLoading] = useState<boolean>(false);
  const [notTrackedModal, setNotTrackedModal] = useState<boolean>(false);

  //Details for Roles based UI
  const accessInfo: AccessInfo = useContext(activityContext)?.accessInfo as AccessInfo;
  const isToday: boolean = useContext(activityContext)?.isToday as boolean;
  const isYesterday: boolean = useContext(activityContext)?.isYesterday as boolean;
  const isTablet: boolean = useContext(activityContext)?.isTablet as boolean;

  const targetRef = useRef<HTMLDivElement>(null);
  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const {
    startDate,
    endDate,
    empDeviceId,
    filterType,
    organization,
    department,
  } = filterData; // Destructuring the filterData state value

  const checkDate = () => {
    let today = moment(new Date()).format("L");
    let yesterday = moment(moment().subtract(1, "day").toDate()).format("L");
    let fromDate = moment(startDate).format("L");
    let toDate = moment(endDate).format("L");

    return (
      (today === fromDate && today === toDate) ||
      (yesterday === fromDate && yesterday === toDate)
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInitialLoad(false);
          if (accessInfo["Haven't Tracked"]) {
            getUntrackedUsersList();
          }
          if (accessInfo["Top Used Productive Apps"]) {
            getProductiveWebApps();
          }
          if (accessInfo["Top Used Unproductive Apps"]) {
            getUnproductiveWebApps();
          }
          if (accessInfo["Top Used Neutral Apps"]) {
            getNeutralWebApps();
          }
          if (accessInfo["Productivity Insights"] && !checkDate()) {
            getInsightsData();
          }
          observer.disconnect();
        } else {
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.25,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [startDate, endDate, empDeviceId, filterType, organization, department, displayBarChart, accessInfo]);

  // useEffect(() => {
  //   if (!initialload) {
  //     getProductiveWebApps();
  //     getUnproductiveWebApps();
  //     getNeutralWebApps();
  //     getUntrackedUsersList();
  //   }
  //   let checks = checkDate();

  //   if (!checks) {
  //     getInsightsData();
  //   }

  //   return () => {
  //     setInsightsData([]);
  //   };
  // }, [filterData, displayBarChart]);

  const getUntrackedUsersList = () => {
    setTrackedLoading(true);
    commonGetService(
      `/api/1.0/dashboard/users/untracked?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setTrackedLoading(false);
      if (res.status === 200) {
        setUntrackedUsers(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getProductiveWebApps = () => {
    setProductiveLoading(true);
    commonGetService(
      `/api/1.0/dashboard/app/productive?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setProductiveLoading(false);
      if (res.status === 200) {
        setProductiveApps(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getUnproductiveWebApps = () => {
    setUnProductiveLoading(true);
    commonGetService(
      `/api/1.0/dashboard/app/unproductive?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setUnProductiveLoading(false);
      if (res.status === 200) {
        setUnproductiveApps(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getNeutralWebApps = () => {
    setNeutralLoading(true);
    commonGetService(
      `/api/1.0/dashboard/app/neutral?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}`
    ).then((res) => {
      setNeutralLoading(false);
      if (res.status === 200) {
        setNeutralApps(res?.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getInsightsData = () => {
    if (filterType !== "employee" && !displayBarChart) {
      setInsightsLoading(true);
      commonGetService(
        `/api/1.0/dashboard/department/insights?${handleFilterApi(
          startDate,
          endDate,
          filterType,
          organization,
          empDeviceId,
          department,
          false
        )}`
      ).then((res) => {
        setInsightsLoading(false);
        if (res.status === 200) {
          let formatedData = res?.data?.map((val: InsigtsData) => {
            return {
              Department: val?.department,
              Productive: val?.productive_hours,
              Unproductive: val?.unproductive_hours,
              Idle: val?.idle_hours,
              Neutral: val?.neutral_hours,
              total_hours: val?.total_hours,
            };
          });
          setInsightsData(formatedData);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      });
    } else if (!displayBarChart) {
      setInsightsLoading(true);
      commonGetService(
        `/api/1.0/dashboard/productivity/insights?${handleFilterApi(
          startDate,
          endDate,
          filterType,
          organization,
          empDeviceId,
          department,
          false
        )}`
      ).then((res) => {
        setInsightsLoading(false);
        if (res.status === 200) {
          let formatedData = res?.data?.map((val: InsigtsData1) => {
            return {
              day: val?.day,
              timestamp: val?.timestamp,
              Productive: val?.productive_hours,
              Unproductive: val?.unproductive_hours,
              Idle: val?.idle_hours,
              Neutral: val?.neutral_hours,
              total_hours: val?.total_hours,
            };
          });
          setInsightsData(formatedData);
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      });
    }
  };

  const handleModalClose = () => {
    setNotTrackedModal(false);
  }

  const handleNotTrackedCard = () => {
    if ((isToday || isYesterday) && accessInfo["Activity Time"]) { //Single Day with Activity time
      if (isTablet && !accessInfo["Top Used Productive Apps"]) {
        return "cus-track-wid";
      }
      else {
        return "single-day";
      }
    }
    else if (!isToday && !isYesterday && accessInfo["Activity Time"]) { //7 or 30 days with Activity time
      if (!accessInfo["Productivity Insights"] && !accessInfo["Hours Tracked"]) {
        if (isTablet && !accessInfo["Top Used Productive Apps"]) {
          return "cus-track-wid";
        }
        else {
          return "single-day";
        }
      }
      else if (accessInfo["Productivity Insights"] && !accessInfo["Hours Tracked"]) {
        if (isTablet && !accessInfo["Top Used Productive Apps"]) {
          return "cus-track-wid";
        }
      }
      else if (accessInfo["Productivity Insights"] && accessInfo["Hours Tracked"]) {
        if (isTablet && !accessInfo["Top Used Productive Apps"]) {
          return "cus-track-wid";
        }
      }
      else if (!accessInfo["Productivity Insights"] && accessInfo["Hours Tracked"]) {
        if (isTablet && !accessInfo["Top Used Productive Apps"]) {
          return "cus-track-wid cus-track-mrn";
        }
        else if (isTablet && accessInfo["Top Used Productive Apps"]) {
          return "cus-track-mrn";
        }
        else {
          return "single-day";
        }
      }
    }
    else if ((isToday || isYesterday) && !accessInfo["Activity Time"]) { //Single Day without Activity time
      if (isTablet) {
        return !accessInfo["Top Used Productive Apps"] ? "cus-track-mrn cus-track-wid" : "cus-track-mrn";
      }
      else {
        return "single-day";
      }
    }
    else if (!isToday && !isYesterday && !accessInfo["Activity Time"]) { //7 or 30 days without Activity time
      if (
        (!accessInfo["Productivity Insights"] && !accessInfo["Hours Tracked"]) ||
        (!accessInfo["Productivity Insights"] && accessInfo["Hours Tracked"])
      ) {
        if (isTablet && !accessInfo["Top Used Productive Apps"]) {
          return "cus-track-wid cus-track-mrn";
        }
        else if (isTablet && accessInfo["Top Used Productive Apps"]) {
          return "cus-track-mrn";
        }
        else {
          return "single-day";
        }
      }
      else if (
        (accessInfo["Productivity Insights"] && !accessInfo["Hours Tracked"]) ||
        (accessInfo["Productivity Insights"] && accessInfo["Hours Tracked"])
      ) {
        if (isTablet && !accessInfo["Top Used Productive Apps"]) {
          return "cus-track-wid";
        }
        else {
          return "";
        }
      }
    }
  }

  const handleAppCard = (type: string) => {
    //Validation checking for "Active time" and "Haven't Tracked"
    const no_active_and_tracked = !accessInfo["Activity Time"] && !accessInfo["Haven't Tracked"];
    const both_active_and_tracked = accessInfo["Activity Time"] && accessInfo["Haven't Tracked"];
    const no_active_yes_tracked = !accessInfo["Activity Time"] && accessInfo["Haven't Tracked"];
    const yes_active_no_tracked = accessInfo["Activity Time"] && !accessInfo["Haven't Tracked"];

    //Validation checking for "Top Used Productive Apps", "Top Used Unproductive Apps" and "Top Used Neutral Apps"
    const all_apps = accessInfo["Top Used Productive Apps"] && accessInfo["Top Used Unproductive Apps"] && accessInfo["Top Used Neutral Apps"];
    const no_apps = !accessInfo["Top Used Productive Apps"] && !accessInfo["Top Used Unproductive Apps"] && !accessInfo["Top Used Neutral Apps"];
    const productive_app_alone = !accessInfo["Top Used Unproductive Apps"] && !accessInfo["Top Used Neutral Apps"];
    const unProductive_app_alone = !accessInfo["Top Used Productive Apps"] && !accessInfo["Top Used Neutral Apps"];
    const neutral_app_alone = !accessInfo["Top Used Productive Apps"] && !accessInfo["Top Used Unproductive Apps"];
    const productive_and_unproductive = accessInfo["Top Used Productive Apps"] && accessInfo["Top Used Unproductive Apps"] && !accessInfo["Top Used Neutral Apps"];
    const productive_and_neutral = accessInfo["Top Used Productive Apps"] && accessInfo["Top Used Neutral Apps"] && !accessInfo["Top Used Unproductive Apps"];
    const unproductive_and_neutral = accessInfo["Top Used Unproductive Apps"] && accessInfo["Top Used Neutral Apps"] && !accessInfo["Top Used Productive Apps"];

    //Validation checking for "Hours Tracked" and "Productivity Insights"
    const both_hours_and_insights = accessInfo["Hours Tracked"] && accessInfo["Productivity Insights"];
    const yes_hours_no_insights = accessInfo["Hours Tracked"] && !accessInfo["Productivity Insights"];
    const no_hours_yes_insights = !accessInfo["Hours Tracked"] && accessInfo["Productivity Insights"];
    const no_hours_and_insights = !accessInfo["Hours Tracked"] && !accessInfo["Productivity Insights"];

    //Validation checking for "Single day" or "Multiple day"
    const single_day = isToday || isYesterday;
    const multiple_day = !isToday && !isYesterday;

    const commonFilterOne = () => {
      if (no_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are not present
        if (filterType !== 'employee' && multiple_day && both_hours_and_insights) {
          if (isTablet) {
            return "cus-prod-six";
          }
          else {
            return "cus-prod-four";
          }
        }
        else if (filterType !== 'employee' && multiple_day && no_hours_yes_insights) {
          return "cus-prod-six";
        }
        else if (filterType === 'employee') {
          return "cus-prod-one";
        }
        else {
          return "cus-prod-two";
        }
      }

      else if (yes_active_no_tracked) {  //When "Active Time" is present and "Haven't Tracked" is not present
        if (multiple_day && both_hours_and_insights) {
          if (isTablet && filterType !== "employee") {
            return "cus-prod-three";
          }
          else {
            return "cus-prod-six";
          }
        }
        else if (multiple_day && yes_hours_no_insights) {
          if (isTablet) {
            return "cus-prod-two";
          }
          else {
            return "cus-prod-three";
          }
        }
        else if (multiple_day && no_hours_yes_insights) {
          if (!isTablet) {
            return "cus-prod-six";
          }
          else {
            return "cus-prod-two";
          }
        }
        else if (filterType === "employee" && isTablet && accessInfo["Timeline"]) {
          return "cus-prod-six";
        }
        return "cus-prod-three";
      }

      else if (no_active_yes_tracked) {  //When "Active Time" is not present and "Haven't Tracked" is present
        if (filterType === "employee" && isTablet) {
          return "cus-prod-one";
        }
        else {
          return "cus-unprod-one";
        }
      }

      else if (both_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are present
        if (filterType === "employee" && isTablet) {
          if (!accessInfo["Timeline"]) {
            if (multiple_day && !accessInfo["Hours Tracked"]) {
              return "cus-prod-three";
            }
            else if (multiple_day && accessInfo["Hours Tracked"]) {
              return "cus-prod-two";
            }
            else {
              return "cus-prod-three";
            }
          }
          else if (accessInfo["Timeline"]) {
            if (multiple_day && !accessInfo["Hours Tracked"]) {
              return "cus-prod-one";
            }
            else if (multiple_day && accessInfo["Hours Tracked"]) {
              return "cus-prod-two";
            }
            else {
              return "cus-prod-one";
            }
          }
        }
        else {
          return "cus-unprod-one";
        }
      }
    }

    const commonFilterTwo = () => {
      if (no_active_and_tracked) { //When "Active Time" and "Haven't Tracked" both are not present
        if (multiple_day && both_hours_and_insights) {
          return "cus-bi-two";
        }
        else if (filterType !== "employee" && multiple_day && no_hours_yes_insights) {
          return "cus-bi-two";
        }
        return "cus-bi-one";
      }

      else if (yes_active_no_tracked) {  //When "Active Time" is present and "Haven't Tracked" is not present
        if (multiple_day && both_hours_and_insights) {
          return "cus-bi-two";
        }
        else if (isTablet && multiple_day && yes_hours_no_insights) {
          return "cus-bi-one";
        }
        else if (multiple_day && no_hours_yes_insights) {
          if (isTablet && filterType === "employee") {
            if (accessInfo["Timeline"]) {
              return "cus-bi-one";
            }
            else {
              return "cus-bi-three";
            }
          }
          else {
            return "cus-bi-two";
          }
        }
        else if (isTablet && filterType === "employee" && accessInfo["Timeline"]) {
          return "cus-bi-one";
        }
        return "cus-bi-three";
      }

      else if (no_active_yes_tracked) {  //When "Active Time" is not present and "Haven't Tracked" is present
        if (isTablet && filterType !== "employee") {
          return "cus-prod-one";
        }
        else if (filterType !== "employee" && multiple_day && both_hours_and_insights) {
          return "cus-bi-four";
        }
        return "cus-bi-two";
      }

      else if (both_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are present
        if (filterType === "employee" && isTablet) {
          if (accessInfo["Timeline"] || (multiple_day && both_hours_and_insights)
            || (multiple_day && yes_hours_no_insights)) {
            return "cus-bi-one";
          }
          return "cus-bi-three";
        }
        else if (filterType !== "employee" && isTablet) {
          return "cus-prod-one";
        }
        else if (filterType !== "employee" && multiple_day && both_hours_and_insights) {
          return "cus-bi-four";
        }
        return "cus-bi-two"
      }
    }

    const commonFilterThree = () => {
      if (no_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are not present
        if (multiple_day && both_hours_and_insights) {
          return "cus-bi-two";
        }
        else if (filterType !== "employee" && multiple_day && no_hours_yes_insights) {
          return "cus-bi-two";
        }
        return "cus-bi-one";
      }

      else if (yes_active_no_tracked) {  //When "Active Time" is present and "Haven't Tracked" is not present
        if (multiple_day && both_hours_and_insights) {
          return "cus-bi-two";
        }
        else if (isTablet && multiple_day && yes_hours_no_insights) {
          return "cus-bi-one";
        }
        else if (multiple_day && no_hours_yes_insights) {
          if (isTablet && filterType === "employee") {
            if (accessInfo["Timeline"]) {
              return "cus-bi-one";
            }
            else {
              return "cus-bi-three";
            }
          }
          else {
            return "cus-bi-two";
          }
        }
        else if (isTablet && filterType === "employee" && accessInfo["Timeline"]) {
          return "cus-bi-one";
        }
        return "cus-bi-three";
      }

      else if (no_active_yes_tracked) {  //When "Active Time" is not present and "Haven't Tracked" is present
        if (filterType !== "employee" && multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
          if (isTablet) {
            return "cus-bi-five";
          }
          else {
            return "cus-bi-four";
          }
        }
        return "cus-bi-two";
      }

      else if (both_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are present
        if (filterType === "employee" && isTablet) {
          if (accessInfo["Timeline"] || (multiple_day && (both_hours_and_insights || yes_hours_no_insights))) {
            return "cus-bi-one";
          }
          return "cus-bi-three";
        }
        else if (filterType !== "employee" && multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
          return "cus-bi-five";
        }
        return "cus-bi-two";
      }
    }

    const commonFilterFour = () => {
      if (no_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are not present
        if (filterType !== "employee") {
          if (multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
            if (isTablet) {
              return "cus-tri-four";
            }
            else {
              return "cus-tri-three";
            }
          }
          else if (isTablet) {
            return "cus-tri-two";
          }
        }
        return "cus-tri-one";
      }

      else if (yes_active_no_tracked) {  //When "Active Time" is present and "Haven't Tracked" is not present
        if (filterType !== "employee") {
          if (multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
            if (isTablet) {
              return "cus-tri-four";
            }
            else {
              return "cus-tri-three";
            }
          }
          else if (multiple_day && yes_hours_no_insights && isTablet) {
            return "cus-tri-two";
          }
          else if (isTablet) {
            return "cus-tri-five";
          }
        }
        else if (filterType === "employee" && isTablet) {
          if (accessInfo["Timeline"] || (multiple_day && both_hours_and_insights) ||
            (multiple_day && yes_hours_no_insights)) {
            return "";
          }
          return "cus-tri-six";
        }
        return "cus-tri-one";
      }

      else if (no_active_yes_tracked) {  //When "Active Time" is not present and "Haven't Tracked" is present
        if (filterType !== "employee" && multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
          return "cus-tri-eight";
        }
        else if (filterType !== "employee" && isTablet) {
          return "cus-tri-seven";
        }
        return "cus-tri-three";
      }

      else if (both_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are present
        if (filterType !== "employee") {
          if (multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
            return "cus-tri-eight";
          }
          else if (multiple_day && yes_hours_no_insights && isTablet) {
            return "cus-tri-seven";
          }
          else if (isTablet) {
            return "cus-tri-nine";
          }
        }
        else if (filterType === "employee") {
          if ((multiple_day && (both_hours_and_insights || yes_hours_no_insights) && isTablet) ||
            (accessInfo["Timeline"] && isTablet)) {
            return "";
          }
          else if (isTablet) {
            return "cus-tri-six";
          }
        }
        return "cus-tri-three";
      }
    }


    //UI styles for Productive apps
    if (type === 'productive') {
      if (productive_app_alone) {
        if (no_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are not present
          if (filterType !== 'employee' && multiple_day && both_hours_and_insights) {
            if (isTablet) {
              return "cus-prod-six";
            }
            else {
              return "cus-prod-four";
            }
          }
          else if (filterType !== 'employee' && multiple_day && no_hours_yes_insights) {
            return "cus-prod-six";
          }
          else if (filterType === 'employee') {
            return "cus-prod-one";
          }
          else {
            return "cus-prod-two";
          }
        }

        else if (yes_active_no_tracked) {  //When "Active Time" is present and "Haven't Tracked" is not present
          if (multiple_day && both_hours_and_insights) {
            if (isTablet && filterType !== "employee") {
              return "cus-prod-three";
            }
            else {
              return "cus-prod-six";
            }
          }
          else if (multiple_day && yes_hours_no_insights) {
            if (isTablet) {
              return "cus-prod-two";
            }
            else {
              return "cus-prod-three";
            }
          }
          else if (multiple_day && no_hours_yes_insights) {
            if (!isTablet) {
              return "cus-prod-six";
            }
            else {
              return "cus-prod-two";
            }
          }
          else if (filterType === "employee" && isTablet && accessInfo["Timeline"]) {
            return "cus-prod-six";
          }
          return "cus-prod-three";
        }

        else if (no_active_yes_tracked) {  //When "Active Time" is not present and "Haven't Tracked" is present
          if (filterType === "employee" && isTablet) {
            return "cus-prod-one";
          }
          else if (isTablet && multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
            return "cus-prod-five";
          }
          else {
            return "cus-prod-four";
          }
        }

        else if (both_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are present
          if (filterType === "employee" && isTablet) {
            if (!accessInfo["Timeline"]) {
              if (multiple_day && !accessInfo["Hours Tracked"]) {
                return "cus-prod-three";
              }
              else if (multiple_day && accessInfo["Hours Tracked"]) {
                return "cus-prod-two";
              }
              else {
                return "cus-prod-three";
              }
            }
            else if (accessInfo["Timeline"]) {
              if (multiple_day && !accessInfo["Hours Tracked"]) {
                return "cus-prod-one";
              }
              else if (multiple_day && accessInfo["Hours Tracked"]) {
                return "cus-prod-two";
              }
              else {
                return "cus-prod-one";
              }
            }
          }
          else if (isTablet && multiple_day && yes_hours_no_insights) {
            return "cus-prod-four";
          }
          else {
            return "cus-prod-five";
          }
        }
      }

      else if (productive_and_unproductive || productive_and_neutral) {
        if (no_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are not present
          if (multiple_day && both_hours_and_insights) {
            return "cus-bi-two";
          }
          else if (filterType !== "employee" && multiple_day && no_hours_yes_insights) {
            return "cus-bi-two";
          }
          return "cus-bi-one";
        }

        else if (yes_active_no_tracked) {  //When "Active Time" is present and "Haven't Tracked" is not present
          if (multiple_day && both_hours_and_insights) {
            return "cus-bi-two";
          }
          else if (isTablet && multiple_day && yes_hours_no_insights) {
            return "cus-bi-one";
          }
          else if (multiple_day && no_hours_yes_insights) {
            if (isTablet && filterType === "employee") {
              if (accessInfo["Timeline"]) {
                return "cus-bi-one";
              }
              else {
                return "cus-bi-three";
              }
            }
            else {
              return "cus-bi-two";
            }
          }
          else if (isTablet && filterType === "employee" && accessInfo["Timeline"]) {
            return "cus-bi-one";
          }
          return "cus-bi-three";
        }

        else if (no_active_yes_tracked) {  //When "Active Time" is not present and "Haven't Tracked" is present
          if (filterType !== "employee") {
            if (isTablet) {
              if (multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
                return "cus-prod-five";
              }
              else {
                return "cus-prod-four";
              }
            }
            else if (multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
              return "cus-bi-four";
            }
          }
          return "cus-bi-two";
        }

        else if (both_active_and_tracked) {  //When "Active Time" and "Haven't Tracked" both are present
          if (filterType === "employee" && isTablet) {
            if (accessInfo["Timeline"] || (multiple_day && both_hours_and_insights)
              || (multiple_day && yes_hours_no_insights)) {
              return "cus-bi-one";
            }
            return "cus-bi-three";
          }
          else if (filterType !== "employee" && isTablet) {
            if (multiple_day && yes_hours_no_insights) {
              return "cus-prod-four";
            }
            return "cus-prod-five";
          }
          else if (filterType !== "employee" && multiple_day && (both_hours_and_insights || no_hours_yes_insights)) {
            return "cus-bi-four";
          }
          return "cus-bi-two"
        }
      }

      else if (all_apps) {
        return commonFilterFour();
      }
    }

    //UI styles for Unproductive apps
    else if (type === 'unProductive') {
      if (unProductive_app_alone) {
        return commonFilterOne();
      }

      else if (productive_and_unproductive) {
        return commonFilterTwo();
      }

      else if (unproductive_and_neutral) {
        return commonFilterThree();
      }

      else if (all_apps) {
        return commonFilterFour();
      }
    }

    //UI styles for Neutral apps
    else if (type === "neutral") {
      if (neutral_app_alone) {
        return commonFilterOne();
      }

      else if (productive_and_neutral) {
        return commonFilterTwo();
      }

      else if (unproductive_and_neutral) {
        return commonFilterThree();
      }

      else if (all_apps) {
        return commonFilterFour();
      }
    }
  }

  return (
    <>
      <div
        className={`d-flex w-100 prod-cont flex-wrap ${filterType === "employee" ? "single-user-reverse" : ""}`}
        ref={targetRef}
      >
        {!displayBarChart && filterType !== "employee" && accessInfo["Productivity Insights"] && (
          <div
            className={`bar-chart-parent styled-scroll-hor  ${insightsData.length > 5 ? "line-overflow" : ""
              } ${!accessInfo["Haven't Tracked"] ? `single-user ${isTablet && accessInfo["Activity Time"] ? "" : "mt-0"}` : ''}
              ${(isTablet && accessInfo["Hours Tracked"] && accessInfo["Activity Time"]) ||
                (isTablet && !accessInfo["Activity Time"]) ? "mt-0" : ""}`}
          >
            <Barcharts
              insightsData={insightsData}
              chartLoading={insightsLoading}
            />
          </div>
        )}
        {filterType !== "employee" && accessInfo["Haven't Tracked"] && (
          <>
            <div className={`never-td-ctr ${handleNotTrackedCard()}`}>
              <div className="never-tracked d-flex flex-column h-100">
                <h5>Haven’t Tracked Yet</h5>

                <div className="ntd-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Designation</th>
                        <th>Reason</th>

                      </tr>
                    </thead>
                    <tbody>
                      {trackedLoading ? (
                        Array.from({ length: 5 })?.map((res, i) => (
                          <tr key={i}>
                            <td>
                              <Skeleton className="w-75" count={1} />
                            </td>
                            <td>
                              <Skeleton className="w-75" count={1} />
                            </td>
                            <td>
                              <Skeleton className="w-75" count={1} />
                            </td>
                            <td>
                              <Skeleton className="w-75" count={1} />
                            </td>
                          </tr>
                        ))
                      ) : unTrackedUsers?.length ? (
                        unTrackedUsers
                          ?.filter?.((val, i) => i < 5)
                          ?.map((res, ind) => (
                            <tr key={ind}>
                              <td>{res?.employee_name}</td>
                              <td>{res?.date}</td>
                              <td>{res?.designation}</td>
                              <td>{res?.reason}</td>

                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td
                            className="no-data d-flex flex-column justify-content-center align-items-center"
                            rowSpan={5}
                          >
                            <Image
                              src="/images/dashboard/user_icon.png"
                              alt="No Data Found"
                            />
                            <p className="m-0">No users to show</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {unTrackedUsers.length > 5 && (
                  <div className="table-rdk cursor-pointer" onClick={() => setNotTrackedModal(true)}>
                    <p>{`+${unTrackedUsers?.length - 5} Count`}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {accessInfo["Top Used Productive Apps"] &&
          <div className={`web-app-card d-flex flex-column ${handleAppCard('productive')}`}>
            <div className="card-title sus-head">
              <h5>Top used productive websites and apps</h5>
            </div>{" "}
            {productiveLoading ? (
              <div className="skeleton-list-apps">
                <AppsSkeleton count={5} />
              </div>
            ) : (
              <>
                {productiveApps.length ? (
                  productiveApps?.map((res, ind) => (
                    <div className="d-flex flex-row app-ds-ctr w-100" key={ind}>
                      <div className="fldr-icon">
                        <Image
                          src="/images/dashboard/folder_icon.svg"
                          alt="Folder"
                        />
                      </div>
                      <div className="app-ptg d-flex flex-column pr-apps">
                        <div className="d-flex flex-row justify-content-between">
                          <p className="app-name" title={res?.app}>{res?.app}</p>
                          <p className="app-hours">{res?.time}</p>
                        </div>
                        <div className="app-pg-bar">
                          <ProgressBar
                            now={res?.percentage}
                            variant={
                              ind === 0
                                ? "green-one"
                                : ind === 1
                                  ? "green-two"
                                  : ind === 2
                                    ? "green-three"
                                    : ind === 3
                                      ? "green-four"
                                      : "green-five"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex flex-row  w-100 no-data-row">
                    <div className="no-data-image-wrapper">
                      <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
                    </div>
                    <p>No Apps to show</p>
                  </div>
                )}
              </>
            )}
          </div>
        }
        {accessInfo["Top Used Unproductive Apps"] &&
          <div className={`web-app-card d-flex flex-column ${handleAppCard('unProductive')}`}>
            <div className="card-title dgr-head">
              <h5>Top used unproductive websites and apps</h5>
            </div>
            {unProductiveLoading ? (
              <div className="skeleton-list-apps">
                <AppsSkeleton count={5} />
              </div>
            ) : (
              <>
                {unProductiveApps.length ? (
                  unProductiveApps?.map((res, ind) => (
                    <div className="d-flex flex-row app-ds-ctr w-100">
                      <div className="fldr-icon">
                        <Image
                          src="/images/dashboard/folder_icon.svg"
                          alt="Folder"
                        />
                      </div>
                      <div className="app-ptg d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between">
                          <p className="app-name" title={res?.app}>{res?.app}</p>
                          <p className="app-hours">{res?.time}</p>
                        </div>
                        <div className="app-pg-bar">
                          <ProgressBar
                            now={res?.percentage}
                            variant={
                              ind === 0
                                ? "red-one"
                                : ind === 1
                                  ? "red-two"
                                  : ind === 2
                                    ? "red-three"
                                    : ind === 3
                                      ? "red-four"
                                      : "red-five"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex flex-row  w-100 no-data-row">
                    <div className="no-data-image-wrapper">
                      <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
                    </div>
                    <p>No Apps to show</p>
                  </div>
                )}
              </>
            )}
          </div>
        }
        {accessInfo["Top Used Neutral Apps"] &&
          <div className={`web-app-card d-flex flex-column ${handleAppCard('neutral')}`}>
            <div className="card-title gre-head">
              <h5>Top used neutral websites and apps</h5>
            </div>
            {neutralLoading ? (
              <div className="skeleton-list-apps">
                <AppsSkeleton count={5} />
              </div>
            ) : (
              <>
                {neutralApps.length ? (
                  neutralApps?.map((res, ind) => (
                    <div className="d-flex flex-row app-ds-ctr w-100" key={ind}>
                      <div className="fldr-icon">
                        <Image
                          src="/images/dashboard/folder_icon.svg"
                          alt="Folder"
                        />
                      </div>
                      <div className="app-ptg d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between w-100">
                          <p className="app-name" title={res?.app}>{res?.app}</p>
                          <p className="app-hours">{res?.time}</p>
                        </div>
                        <div className="app-pg-bar">
                          <ProgressBar
                            now={res?.percentage}
                            variant={ind === 0 ? "blue-one" : "blue-two"}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex flex-row  w-100 no-data-row">
                    <div className="no-data-image-wrapper">
                      <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
                    </div>
                    <p>No Apps to show</p>
                  </div>
                )}
              </>
            )}
          </div>
        }
        {!displayBarChart && filterType === "employee" && accessInfo["Productivity Insights"] && (
          <div
            className={`bar-chart-parent  ${insightsData.length > 5 ? "line-overflow" : ""
              } single-user styled-scroll-hor ${!accessInfo["Top Used Productive Apps"] && !accessInfo["Top Used Unproductive Apps"] &&
                !accessInfo["Top Used Neutral Apps"] ? "mt-0" : ""}`}
          >
            <Barcharts
              insightsData={insightsData}
              chartLoading={insightsLoading}
            />
          </div>
        )}
      </div >
      <NotTrackedList show={notTrackedModal} handleModalClose={handleModalClose} unTrackedUsers={unTrackedUsers} />
    </>
  );
};

export default ProductiveDetails;
