import React, { useState, useEffect } from "react";
import "../../styles/tasklist.scss";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { AppsList } from "./Tracking";
import { commonPostservice, commonPutService } from "../../utils/properties";
import AppLoadingSkeleton from "../Skeletons/AppLoadingSkeleton";

type Task = {
    id: string | number;
    name: string;
    image: string;
    status: string;

};

type TaskListProps = {
    tasks: AppsList[];
    getAppsList: () => void;
    currentAdminRole: string

};

const TaskList: React.FC<TaskListProps> = ({ tasks: initialTasks, getAppsList: getAppsList, currentAdminRole }) => {


    const [tasks, setTasks] = useState<AppsList[]>([]);
    const [hoveredTask, setHoveredTask] = useState<string | null | number>(null);


    const [productiveTasks, setProductiveTasks] = useState<AppsList[]>([])
    const [unproductiveTasks, setUnproductiveTasks] = useState<AppsList[]>([])
    const [nuetralTasks, setNuetralTasks] = useState<AppsList[]>([])
    const [noData, setNoData] = useState<boolean>(false);
    const [noData1, setNoData1] = useState<boolean>(false);

    const [noData2, setNoData2] = useState<boolean>(false);



    const [searchText, setSearchText] = useState<string>("")
    const [searchText1, setSearchText1] = useState<string>("")
    const [searchText2, setSearchText2] = useState<string>("")




    useEffect(() => {
        setTasks(initialTasks);

    }, [initialTasks]);

    useEffect(() => {
        if (searchText) {
            let newTask = initialTasks.filter((task) => task.trend_name === "productive").filter((task) => task.app.toLowerCase().includes(searchText.toLowerCase()));
            setProductiveTasks(newTask);
            if (newTask.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        } else if (!searchText) {
            setProductiveTasks(initialTasks.filter((task) => task.trend_name === "productive"))

        }
        if (searchText1) {
            let newTask = initialTasks.filter((task) => task.trend_name === "unproductive").filter((task) => task.app.toLowerCase().includes(searchText1.toLowerCase()));
            setUnproductiveTasks(newTask)
            if (newTask.length == 0) {
                setNoData1(true)
            } else {
                setNoData1(false)
            }
        } else if (!searchText1) {
            setUnproductiveTasks(initialTasks.filter((task) => task.trend_name === "unproductive"))


        }
        if (searchText2) {
            let newTask = initialTasks.filter((task) => task.trend_name === "neutral").filter((task) => task.app.toLowerCase().includes(searchText2.toLowerCase()));
            setNuetralTasks(newTask)
            if (newTask.length == 0) {
                setNoData2(true)
            } else {
                setNoData2(false)
            }
        } else if (!searchText2) {
            setNuetralTasks(initialTasks.filter((task) => task.trend_name === "neutral"))


        }

    }, [searchText, searchText1, searchText2, initialTasks])



    const onDragStart = (evt: React.DragEvent<HTMLDivElement>) => {

        const element = evt.currentTarget;
        element.classList.add("dragged");
        evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
        evt.dataTransfer.effectAllowed = "move";
    };

    const onDragEnd = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove("dragged");
    };

    const onDragEnter = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const element = evt.currentTarget;
        element.classList.add("dragged-over");
        evt.dataTransfer.dropEffect = "move";
    };

    const onDragLeave = (evt: React.DragEvent<HTMLDivElement>) => {
        const currentTarget = evt.currentTarget;
        const newTarget = evt.relatedTarget as HTMLElement | null;
        if (newTarget?.parentNode === currentTarget || newTarget === currentTarget) return;
        evt.preventDefault();
        const element = currentTarget;
        element.classList.remove("dragged-over");
    };

    const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
    };

    const onDrop = (evt: React.DragEvent<HTMLDivElement>, status: string) => {
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        const data = evt.dataTransfer.getData("text/plain");
        const updatedTasks = tasks.map((task) => {
            if (task.id.toString() === data.toString()) {
                task.trend_name = status;
                let params = {
                    id: task.id,
                    trend: status
                }
                commonPutService(`/api/1.0/setting/trend`, params).then((res) => {
                    if (res.status === 200) {
                        getAppsList();

                    } else {
                    }

                });
            }
            return task;
        });
        setTasks(updatedTasks);
    };

    const handleDelete = (id: string | number) => {
        const updatedTasks = tasks.map((task) => {
            if (task.id.toString() === id.toString()) {
                task.trend_name = "neutral";
                let params = {
                    id: task.id,
                    trend: "neutral"
                }

                commonPutService(`/api/1.0/setting/trend`, params).then((res) => {
                    if (res.status === 200) {
                        getAppsList();
                    } else {
                    }
                });
            }
            return task;
        });
        setTasks(updatedTasks);

    };


    return (
        <div className=" d-flex">
            <Row className="w-100 drag-drop-section-row">
                <Col lg={3}>
                    <div
                        className="Productive-apps-box"
                        onDragLeave={onDragLeave}
                        onDragEnter={onDragEnter}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, "productive")}
                    >
                        <div className="box-heading d-flex justify-content-between align-items-center">Productive websites and apps </div>
                        <div className="box-content">

                            <div className="search-bar position-relative screenshots-search d-flex align-items-center justify-content-start">

                                <Image
                                    src="/images/dashboard/grey_search.svg"
                                    alt="Search_Icon"
                                    className="position-absolute"
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Search for app or website"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onFocus={() => { setSearchText1(""); setSearchText2(""); }}
                                />
                            </div>

                            <div className={`drag_column ${productiveTasks.length > 5 ? `scroll` : ""}`}>
                                <div className="drag_row">

                                    {productiveTasks.length > 0 ? productiveTasks.map((task) => (
                                        <div
                                            className="  d-flex flex-row draggable-items justify-content-between align-items-center"
                                            key={task.app}
                                            id={task.id.toString()}
                                            draggable={currentAdminRole.toLowerCase() === 'admin' ? true : false}
                                            onDragStart={onDragStart}
                                            onDragEnd={onDragEnd}
                                            onMouseEnter={() => setHoveredTask(task.id)}
                                            onMouseLeave={() => setHoveredTask(null)}
                                        >

                                            <div className="d-flex flex-row align-items-center">
                                                {task.image && <div className="imag">
                                                    <img src={!!task.image ? task.image : '/images/dashboard/app_default_icon.svg'} alt="box" className="app-icon" onError={(e) => {
                                                        (e.target as HTMLImageElement).src = '/images/dashboard/app_default_icon.svg';
                                                    }} />
                                                </div>}
                                                <div className="card_right ">
                                                    <div className="status ellipsis" title={task.app}>{task.app}</div>

                                                </div>
                                            </div>
                                            {hoveredTask === task.id && task.trend_name !== "neutral" && currentAdminRole.toLowerCase() === "admin" && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className="cursor-pointer" onClick={() => handleDelete(task.id)}>
                                                    <path d="M7.24112 0.0908813H6.08961C5.63151 0.0908813 5.19217 0.272861 4.86824 0.596788C4.54431 0.920714 4.36233 1.36005 4.36233 1.81815V2.39391H0.907789C0.755088 2.39391 0.608642 2.45457 0.500667 2.56255C0.392691 2.67052 0.332031 2.81697 0.332031 2.96967C0.332031 3.12237 0.392691 3.26882 0.500667 3.37679C0.608642 3.48477 0.755088 3.54543 0.907789 3.54543H1.48355V11.606C1.48355 12.2168 1.72619 12.8026 2.15809 13.2345C2.58999 13.6664 3.17578 13.9091 3.78658 13.9091H9.54415C10.155 13.9091 10.7407 13.6664 11.1726 13.2345C11.6045 12.8026 11.8472 12.2168 11.8472 11.606V3.54543H12.4229C12.5756 3.54543 12.7221 3.48477 12.8301 3.37679C12.938 3.26882 12.9987 3.12237 12.9987 2.96967C12.9987 2.81697 12.938 2.67052 12.8301 2.56255C12.7221 2.45457 12.5756 2.39391 12.4229 2.39391H8.96839V1.81815C8.96839 1.36005 8.78642 0.920714 8.46249 0.596788C8.13856 0.272861 7.69922 0.0908813 7.24112 0.0908813ZM5.51385 1.81815C5.51385 1.66545 5.57451 1.51901 5.68249 1.41103C5.79046 1.30306 5.93691 1.2424 6.08961 1.2424H7.24112C7.39382 1.2424 7.54027 1.30306 7.64824 1.41103C7.75622 1.51901 7.81688 1.66545 7.81688 1.81815V2.39391H5.51385V1.81815ZM10.6957 11.606C10.6957 11.9114 10.5743 12.2043 10.3584 12.4203C10.1424 12.6362 9.84955 12.7575 9.54415 12.7575H3.78658C3.48118 12.7575 3.18828 12.6362 2.97233 12.4203C2.75638 12.2043 2.63506 11.9114 2.63506 11.606V3.54543H10.6957V11.606Z" fill="#969696" />
                                                    <path d="M6.6656 5.27252C6.5129 5.27252 6.36645 5.33318 6.25848 5.44116C6.1505 5.54913 6.08984 5.69558 6.08984 5.84828V10.4543C6.08984 10.607 6.1505 10.7535 6.25848 10.8615C6.36645 10.9694 6.5129 11.0301 6.6656 11.0301C6.8183 11.0301 6.96475 10.9694 7.07272 10.8615C7.1807 10.7535 7.24136 10.607 7.24136 10.4543V5.84828C7.24136 5.69558 7.1807 5.54913 7.07272 5.44116C6.96475 5.33318 6.8183 5.27252 6.6656 5.27252Z" fill="#969696" />
                                                    <path d="M8.39258 10.4546C8.39258 10.6073 8.45324 10.7537 8.56121 10.8617C8.66919 10.9697 8.81564 11.0303 8.96834 11.0303C9.12104 11.0303 9.26748 10.9697 9.37546 10.8617C9.48343 10.7537 9.54409 10.6073 9.54409 10.4546V5.84852C9.54409 5.69582 9.48343 5.54938 9.37546 5.4414C9.26748 5.33343 9.12104 5.27277 8.96834 5.27277C8.81564 5.27277 8.66919 5.33343 8.56121 5.4414C8.45324 5.54938 8.39258 5.69582 8.39258 5.84852V10.4546Z" fill="#969696" />
                                                    <path d="M4.36287 5.27277C4.21017 5.27277 4.06372 5.33343 3.95574 5.4414C3.84777 5.54938 3.78711 5.69582 3.78711 5.84852V10.4546C3.78711 10.6073 3.84777 10.7537 3.95574 10.8617C4.06372 10.9697 4.21017 11.0303 4.36287 11.0303C4.51557 11.0303 4.66201 10.9697 4.76999 10.8617C4.87796 10.7537 4.93862 10.6073 4.93862 10.4546V5.84852C4.93862 5.69582 4.87796 5.54938 4.76999 5.4414C4.66201 5.33343 4.51557 5.27277 4.36287 5.27277Z" fill="#969696" />
                                                </svg>)}
                                        </div>)) : noData ? <p className="d-flex justify-content-center align-items-center p-2">No data found</p> : (<div className="skeleton-list-wrapper">
                                            <AppLoadingSkeleton count={4} />
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>

                    <div
                        className="unproductive-app-box"
                        onDragLeave={onDragLeave}
                        onDragEnter={onDragEnter}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, "unproductive")}
                    >

                        <div className="box-heading d-flex justify-content-between align-items-center">Unproductive websites and apps  </div>
                        <div className="box-content">

                            <div className="search-bar position-relative screenshots-search d-flex align-items-center justify-content-start">

                                <Image
                                    src="/images/dashboard/grey_search.svg"
                                    alt="Search_Icon"
                                    className="position-absolute"
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Search for app or website"
                                    value={searchText1}
                                    onChange={(e) => setSearchText1(e.target.value)}
                                    onFocus={() => { setSearchText(""); setSearchText2(""); }}
                                />
                            </div>

                            <div className={`drag_column ${unproductiveTasks.length > 5 ? `scroll` : ""}`}>
                                <div className="drag_row">

                                    {unproductiveTasks.length > 0 ? unproductiveTasks.map((task) => (
                                        <div
                                            className="  d-flex flex-row draggable-items justify-content-between align-items-center"
                                            key={task.app}
                                            id={task.id.toString()}
                                            draggable={currentAdminRole.toLowerCase() === 'admin' ? true : false}
                                            onDragStart={onDragStart}
                                            onDragEnd={onDragEnd}
                                            onMouseEnter={() => setHoveredTask(task.id)}
                                            onMouseLeave={() => setHoveredTask(null)}
                                        >

                                            <div className="d-flex flex-row align-items-center">
                                                {task.image && <div className="imag">
                                                    <img src={!!task.image ? task.image : '/images/dashboard/app_default_icon.svg'} alt="box" className="app-icon" onError={(e) => {
                                                        (e.target as HTMLImageElement).src = '/images/dashboard/app_default_icon.svg';
                                                    }} />
                                                </div>}
                                                <div className="card_right ">
                                                    <div className="status ellipsis" title={task.app}>{task.app}</div>

                                                </div>
                                            </div>
                                            {hoveredTask === task.id && task.trend_name !== "neutral" && currentAdminRole.toLowerCase() === "admin" && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className="cursor-pointer" onClick={() => handleDelete(task.id)}>
                                                    <path d="M7.24112 0.0908813H6.08961C5.63151 0.0908813 5.19217 0.272861 4.86824 0.596788C4.54431 0.920714 4.36233 1.36005 4.36233 1.81815V2.39391H0.907789C0.755088 2.39391 0.608642 2.45457 0.500667 2.56255C0.392691 2.67052 0.332031 2.81697 0.332031 2.96967C0.332031 3.12237 0.392691 3.26882 0.500667 3.37679C0.608642 3.48477 0.755088 3.54543 0.907789 3.54543H1.48355V11.606C1.48355 12.2168 1.72619 12.8026 2.15809 13.2345C2.58999 13.6664 3.17578 13.9091 3.78658 13.9091H9.54415C10.155 13.9091 10.7407 13.6664 11.1726 13.2345C11.6045 12.8026 11.8472 12.2168 11.8472 11.606V3.54543H12.4229C12.5756 3.54543 12.7221 3.48477 12.8301 3.37679C12.938 3.26882 12.9987 3.12237 12.9987 2.96967C12.9987 2.81697 12.938 2.67052 12.8301 2.56255C12.7221 2.45457 12.5756 2.39391 12.4229 2.39391H8.96839V1.81815C8.96839 1.36005 8.78642 0.920714 8.46249 0.596788C8.13856 0.272861 7.69922 0.0908813 7.24112 0.0908813ZM5.51385 1.81815C5.51385 1.66545 5.57451 1.51901 5.68249 1.41103C5.79046 1.30306 5.93691 1.2424 6.08961 1.2424H7.24112C7.39382 1.2424 7.54027 1.30306 7.64824 1.41103C7.75622 1.51901 7.81688 1.66545 7.81688 1.81815V2.39391H5.51385V1.81815ZM10.6957 11.606C10.6957 11.9114 10.5743 12.2043 10.3584 12.4203C10.1424 12.6362 9.84955 12.7575 9.54415 12.7575H3.78658C3.48118 12.7575 3.18828 12.6362 2.97233 12.4203C2.75638 12.2043 2.63506 11.9114 2.63506 11.606V3.54543H10.6957V11.606Z" fill="#969696" />
                                                    <path d="M6.6656 5.27252C6.5129 5.27252 6.36645 5.33318 6.25848 5.44116C6.1505 5.54913 6.08984 5.69558 6.08984 5.84828V10.4543C6.08984 10.607 6.1505 10.7535 6.25848 10.8615C6.36645 10.9694 6.5129 11.0301 6.6656 11.0301C6.8183 11.0301 6.96475 10.9694 7.07272 10.8615C7.1807 10.7535 7.24136 10.607 7.24136 10.4543V5.84828C7.24136 5.69558 7.1807 5.54913 7.07272 5.44116C6.96475 5.33318 6.8183 5.27252 6.6656 5.27252Z" fill="#969696" />
                                                    <path d="M8.39258 10.4546C8.39258 10.6073 8.45324 10.7537 8.56121 10.8617C8.66919 10.9697 8.81564 11.0303 8.96834 11.0303C9.12104 11.0303 9.26748 10.9697 9.37546 10.8617C9.48343 10.7537 9.54409 10.6073 9.54409 10.4546V5.84852C9.54409 5.69582 9.48343 5.54938 9.37546 5.4414C9.26748 5.33343 9.12104 5.27277 8.96834 5.27277C8.81564 5.27277 8.66919 5.33343 8.56121 5.4414C8.45324 5.54938 8.39258 5.69582 8.39258 5.84852V10.4546Z" fill="#969696" />
                                                    <path d="M4.36287 5.27277C4.21017 5.27277 4.06372 5.33343 3.95574 5.4414C3.84777 5.54938 3.78711 5.69582 3.78711 5.84852V10.4546C3.78711 10.6073 3.84777 10.7537 3.95574 10.8617C4.06372 10.9697 4.21017 11.0303 4.36287 11.0303C4.51557 11.0303 4.66201 10.9697 4.76999 10.8617C4.87796 10.7537 4.93862 10.6073 4.93862 10.4546V5.84852C4.93862 5.69582 4.87796 5.54938 4.76999 5.4414C4.66201 5.33343 4.51557 5.27277 4.36287 5.27277Z" fill="#969696" />
                                                </svg>)}
                                        </div>)) : noData1 ? <p className="d-flex justify-content-center align-items-center p-2">No data found</p> : (<div className="skeleton-list-wrapper">
                                            <AppLoadingSkeleton count={4} />
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>

                    <div
                        className="nuetral-app-box"
                        onDragLeave={onDragLeave}
                        onDragEnter={onDragEnter}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, "neutral")}
                    >

                        <div className="box-heading d-flex justify-content-between align-items-center">Neutral websites and apps  </div>
                        <div className="box-content">

                            <div className="search-bar position-relative screenshots-search d-flex align-items-center justify-content-start">

                                <Image
                                    src="/images/dashboard/grey_search.svg"
                                    alt="Search_Icon"
                                    className="position-absolute"
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Search for app or website"
                                    value={searchText2}
                                    onChange={(e) => setSearchText2(e.target.value)}
                                    onFocus={() => { setSearchText1(""); setSearchText(""); }}
                                />
                            </div>

                            <div className={`drag_column ${nuetralTasks.length > 5 ? `scroll` : ""}`}>
                                <div className="drag_row">

                                    {nuetralTasks.length > 0 ? nuetralTasks.map((task) => (
                                        <div
                                            className="  d-flex flex-row draggable-items justify-content-between align-items-center"
                                            key={task.app}
                                            id={task.id.toString()}
                                            draggable={currentAdminRole.toLowerCase() === 'admin' ? true : false}
                                            onDragStart={onDragStart}
                                            onDragEnd={onDragEnd}
                                            onMouseEnter={() => setHoveredTask(task.id)}
                                            onMouseLeave={() => setHoveredTask(null)}
                                        >

                                            <div className="d-flex flex-row align-items-center">
                                                <div className="imag">
                                                    <img src={!!task.image ? task.image : '/images/dashboard/app_default_icon.svg'} alt="box" className="app-icon" onError={(e) => {
                                                        (e.target as HTMLImageElement).src = '/images/dashboard/app_default_icon.svg';
                                                    }} />
                                                </div>
                                                <div className="card_right ">
                                                    <div className="status ellipsis" title={task.app}>{task.app}</div>

                                                </div>
                                            </div>
                                            {hoveredTask === task.id && task.trend_name !== "neutral" && currentAdminRole.toLowerCase() === "admin" && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className="cursor-pointer" onClick={() => handleDelete(task.id)}>
                                                    <path d="M7.24112 0.0908813H6.08961C5.63151 0.0908813 5.19217 0.272861 4.86824 0.596788C4.54431 0.920714 4.36233 1.36005 4.36233 1.81815V2.39391H0.907789C0.755088 2.39391 0.608642 2.45457 0.500667 2.56255C0.392691 2.67052 0.332031 2.81697 0.332031 2.96967C0.332031 3.12237 0.392691 3.26882 0.500667 3.37679C0.608642 3.48477 0.755088 3.54543 0.907789 3.54543H1.48355V11.606C1.48355 12.2168 1.72619 12.8026 2.15809 13.2345C2.58999 13.6664 3.17578 13.9091 3.78658 13.9091H9.54415C10.155 13.9091 10.7407 13.6664 11.1726 13.2345C11.6045 12.8026 11.8472 12.2168 11.8472 11.606V3.54543H12.4229C12.5756 3.54543 12.7221 3.48477 12.8301 3.37679C12.938 3.26882 12.9987 3.12237 12.9987 2.96967C12.9987 2.81697 12.938 2.67052 12.8301 2.56255C12.7221 2.45457 12.5756 2.39391 12.4229 2.39391H8.96839V1.81815C8.96839 1.36005 8.78642 0.920714 8.46249 0.596788C8.13856 0.272861 7.69922 0.0908813 7.24112 0.0908813ZM5.51385 1.81815C5.51385 1.66545 5.57451 1.51901 5.68249 1.41103C5.79046 1.30306 5.93691 1.2424 6.08961 1.2424H7.24112C7.39382 1.2424 7.54027 1.30306 7.64824 1.41103C7.75622 1.51901 7.81688 1.66545 7.81688 1.81815V2.39391H5.51385V1.81815ZM10.6957 11.606C10.6957 11.9114 10.5743 12.2043 10.3584 12.4203C10.1424 12.6362 9.84955 12.7575 9.54415 12.7575H3.78658C3.48118 12.7575 3.18828 12.6362 2.97233 12.4203C2.75638 12.2043 2.63506 11.9114 2.63506 11.606V3.54543H10.6957V11.606Z" fill="#969696" />
                                                    <path d="M6.6656 5.27252C6.5129 5.27252 6.36645 5.33318 6.25848 5.44116C6.1505 5.54913 6.08984 5.69558 6.08984 5.84828V10.4543C6.08984 10.607 6.1505 10.7535 6.25848 10.8615C6.36645 10.9694 6.5129 11.0301 6.6656 11.0301C6.8183 11.0301 6.96475 10.9694 7.07272 10.8615C7.1807 10.7535 7.24136 10.607 7.24136 10.4543V5.84828C7.24136 5.69558 7.1807 5.54913 7.07272 5.44116C6.96475 5.33318 6.8183 5.27252 6.6656 5.27252Z" fill="#969696" />
                                                    <path d="M8.39258 10.4546C8.39258 10.6073 8.45324 10.7537 8.56121 10.8617C8.66919 10.9697 8.81564 11.0303 8.96834 11.0303C9.12104 11.0303 9.26748 10.9697 9.37546 10.8617C9.48343 10.7537 9.54409 10.6073 9.54409 10.4546V5.84852C9.54409 5.69582 9.48343 5.54938 9.37546 5.4414C9.26748 5.33343 9.12104 5.27277 8.96834 5.27277C8.81564 5.27277 8.66919 5.33343 8.56121 5.4414C8.45324 5.54938 8.39258 5.69582 8.39258 5.84852V10.4546Z" fill="#969696" />
                                                    <path d="M4.36287 5.27277C4.21017 5.27277 4.06372 5.33343 3.95574 5.4414C3.84777 5.54938 3.78711 5.69582 3.78711 5.84852V10.4546C3.78711 10.6073 3.84777 10.7537 3.95574 10.8617C4.06372 10.9697 4.21017 11.0303 4.36287 11.0303C4.51557 11.0303 4.66201 10.9697 4.76999 10.8617C4.87796 10.7537 4.93862 10.6073 4.93862 10.4546V5.84852C4.93862 5.69582 4.87796 5.54938 4.76999 5.4414C4.66201 5.33343 4.51557 5.27277 4.36287 5.27277Z" fill="#969696" />
                                                </svg>)}
                                        </div>)) : noData2 ? <p className="d-flex justify-content-center align-items-center p-2">No data found</p> : (<div className="skeleton-list-wrapper">
                                            <AppLoadingSkeleton count={4} />
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>

        </div>
    );
};

export default TaskList;
