import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import Select from '../CustomComponents/select/Select'
import TaskList from './Tasklist'
import { commonGetService, commonPutService } from '../../utils/properties'
import { useAppDispatch } from '../../app/hooks'
import { showToast } from '../../features/Toaster/toastslice'
import { parse } from 'path'


export interface AppsList {
    app: string
    image?: string,
    id: string,
    trend_name: string
}



interface trackingProps {
    refresh1: boolean,
    currentRole: string,
}


const Tracking = ({ refresh1, currentRole }: trackingProps) => {
    const dispatch = useAppDispatch();

    const screenshotOptions = [
        { value: "180", title: "Every 3 minutes" },
        { value: "300", title: "Every 5 minutes" },
        { value: "900", title: "Every 15 minutes" },
        { value: "1800", title: "Every 30 minutes" },
    ]

    const idleTimeOptions = [{
        value: "30",
        title: "30 Seconds"
    },
    {
        value: "45",
        title: "45 Seconds"
    },
    {
        value: "60",
        title: "1 Minute"
    }, {
        value: "180",
        title: "3 Minutes"
    },]
    const inactivityTimeOptions = [{ value: "180", title: " 3 minutes" },
    { value: "300", title: " 5 minutes" },
    { value: "900", title: " 15 minutes" },
    { value: "1800", title: " 30 minutes" },]

    const [appsList, setAppsList] = useState<AppsList[]>([]);

    const [screenshotInterval, setScreenshotInterval] = useState<string>("");
    const [idleInterval, setIdleInterval] = useState<string>("");
    const [inactivityInterval, setInactivityInterval] = useState<string>("");
    const [screenshotEnabled, setScreenshotEnabled] = useState<boolean>(false);
    const [idleEnabled, setIdleEnabled] = useState<boolean>(false);
    const [inactivityEnabled, setInactivityEnabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [screenshotError, setScreenshotError] = useState<boolean>(false);
    const [idleError, setIdleError] = useState<boolean>(false);
    const [inactivityError, setInactivityError] = useState<boolean>(false);
    useEffect(() => {
        getAppsList()
    }, [refresh1])


    const handleDataSave = () => {
        if (!screenshotInterval) setScreenshotError(true);
        if (!idleInterval) setIdleError(true);
        if (!inactivityInterval) setInactivityError(true);

        if (!screenshotInterval || !idleInterval || !inactivityInterval) {
            dispatch(
                showToast({
                    message: "Fill the required fields",
                    status: "retry",
                    visible: true
                })
            )
            return;
        }
        else {
            commonPutService(`/api/1.0/setting/reset/time`, {
                screenshot_time: parseInt(screenshotInterval),
                idle_time: parseInt(idleInterval),
                inactivity_time: parseInt(inactivityInterval),
                screenshot_time_status: screenshotEnabled,
                idle_time_status: idleEnabled,
                inactivity_time_status: inactivityEnabled
            }).then(res => {
                if (res.status === 200) {
                    dispatch(
                        showToast({
                            message: "Data updated successfully",
                            status: "success",
                            visible: true
                        })
                    )

                } else {
                    dispatch(
                        showToast({
                            message: "Something went wrong",
                            status: "retry",
                            visible: true
                        }))
                }
            })

        }
    }


    const getAppsList = () => {
        commonGetService(
            `/api/1.0/setting/trends`
        ).then((res) => {
            if (res.status === 200) {
                setAppsList(res?.data);



            }
            else {
                setAppsList([]);
            }
        });
        commonGetService(
            `/api/1.0/setting/reset/time`
        ).then((res) => {
            if (res.status === 200) {


                // setAppsList(res?.data);
                res?.data?.screenshot_time && setScreenshotInterval(res?.data?.screenshot_time.toString());
                res?.data?.idle_time && setIdleInterval(res?.data?.idle_time.toString());
                res?.data?.inactivity_time && setInactivityInterval(res?.data?.inactivity_time.toString());
                setScreenshotEnabled(res?.data?.screenshot_time_status);
                setIdleEnabled(res?.data?.idle_time_status);
                setInactivityEnabled(res?.data?.inactivity_time_status);
                setIsLoading(false);



            }
            else {
                setIdleEnabled(false);
                setInactivityEnabled(false);
                setScreenshotEnabled(false);
                setScreenshotInterval("");
                setIdleInterval("");
                setInactivityInterval("");
                setIsLoading(false);

                // setAppsList([]);
            }
            setIsLoading(false);

        });
    }






    return (
        <div className='tracking'>
            {isLoading ?
                <div className="d-flex justify-content-center align-items-center h-100 crt-loader h-20">
                    <img src="/images/loader/content_loader.gif" />
                    {/* <Spinner animation="border" /> */}
                </div> :
                <>
                    <h5>Categorise websites and apps</h5>
                    <p className="tracking-desc">Customise your tracking by classifying apps and websites as productive, unproductive, or neutral for precise monitoring.</p>

                    <TaskList tasks={appsList} getAppsList={getAppsList} currentAdminRole={currentRole} />


                    <Col lg={7}>
                        <div className='d-flex mg-top-from-drgn-drop align-items-center justify-content-between'>
                            <div className='d-block text-left'>
                                <h5 className=''>Capture Screenshots</h5>
                                <p className='tracking-desc screenshot-desc'>Take screenshots to capture visual records of your employee's activity at specific intervals.</p>
                            </div>
                            <Form.Check
                                type="switch"
                                checked={screenshotEnabled}
                                // disabled={path[1] === "viewroles"}
                                onChange={(e) => { setScreenshotEnabled(e.target.checked) }}
                                className='cursor-pointer'
                                disabled={currentRole.toLowerCase() === "admin" ? false : true}


                            />
                        </div>
                    </Col>
                    <Col className="p-0 " lg={2}>
                        <Select
                            value={screenshotInterval}
                            options={screenshotOptions}
                            onChange={(val) => { setScreenshotInterval(val); setScreenshotError(false) }}
                            placeholder="Select one"
                            width="100%"
                            margin="0 0 1.5rem 0"
                            className={`${screenshotError ? 'error-msg' : ''}`}
                            disabled={currentRole.toLowerCase() === "admin" ? !screenshotEnabled : true}
                        />
                    </Col>
                    <Col lg={7}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-block text-left'>
                                <h5>Idle Time</h5>
                                <p className='tracking-desc screenshot-desc'>Set the duration of inactivity before idle time begins.</p>
                            </div>
                            <Form.Check
                                type="switch"
                                checked={idleEnabled}
                                onChange={(e) => { setIdleEnabled(e.target.checked) }

                                }
                                disabled={currentRole.toLowerCase() === "admin" ? false : true}

                            />
                        </div>
                    </Col>
                    <Col className="p-0 " lg={2}>
                        <Select
                            value={idleInterval}
                            options={idleTimeOptions}
                            onChange={(val) => { setIdleInterval(val); setIdleError(false) }}
                            placeholder="Select one"
                            width="100%"
                            margin="0 0 1.5rem 0"
                            disabled={currentRole.toLowerCase() === 'admin' ? !idleEnabled : true}
                            className={`${idleError ? 'error-msg' : ''}`}
                        />
                    </Col>
                    <Col lg={7}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-block text-left'>
                                <h5> Time out after</h5>
                                <p className='tracking-desc screenshot-desc'>This is the period of no keyboard or mouse activity after which the desktop app will automatically stop tracking a user's computer activity.
                                    <br></br> "Off" means that the desktop app will never automatically stop tracking.</p>
                            </div>
                            <Form.Check
                                type="switch"
                                checked={inactivityEnabled}
                                onChange={(e) => { setInactivityEnabled(e.target.checked) }
                                }
                                disabled={currentRole.toLowerCase() === 'admin' ? false : true}
                            />
                        </div>
                    </Col>
                    <Col className="p-0 " lg={2}>
                        <Select
                            value={inactivityInterval}
                            options={inactivityTimeOptions}
                            onChange={(val) => { setInactivityInterval(val); setInactivityError(false) }}
                            placeholder="Select one"
                            width="100%"
                            margin="0 0 1.5rem 0"
                            disabled={currentRole.toLowerCase() === 'admin' ? !inactivityEnabled : true}
                            className={`${inactivityError ? 'error-msg' : ''}`}
                        />
                    </Col>
                    {currentRole.toLowerCase() === 'admin' && <Button className='add-btn' disabled={false} onClick={() => { handleDataSave() }}>Save</Button>}
                </>}
        </div>
    )
}

export default Tracking